<template>
  <div>
      <van-nav-bar
        :title="title[type]"
        left-text="返回"
        left-arrow
        @click-left="$router.go(-1)"/>
        <van-dialog v-model="smsshow" title="短信验证" @confirm='beforeClose' show-cancel-button>
            <div style="padding:5px 10px;text-align:center;">
                短信已发送到{{phone}}
            </div>
          <van-field
            v-model="smsCode"
            center
            clearable
            label="验证码"
            placeholder="请输入短信验证码">
            </van-field>
      </van-dialog>
        <div class="app_body">
            <div class="app_bank">
                <van-cell title="银行卡" @click="showCard"  is-link>
                    <template #default>
                        <span style="font-size:14px;color:black;">{{cardName}}</span>
                    </template>
                </van-cell>
            </div>
            <div class="input_div">
                <span>&nbsp;&nbsp;&nbsp; {{title[type]}}金额（元）</span>
                <van-field v-model="amount" size='large' :autofocus='true' type="number" placeholder="请输入金额" />
            </div>
            <div class="submit_btn">
                <van-button block type="primary" @click="onSubmit" style="width:80%;margin:0px auto;">
                        {{title[type]}}
                </van-button>
            </div>
        </div>
        <van-action-sheet
            v-model="show"
            :actions="actions"
            @select="onSelect"
            cancel-text="取消"
            description="请选择银行卡"
            close-on-click-action/>
  </div>
</template>
<script>
import { Button,NavBar,CellGroup,Cell,Field,ActionSheet, Dialog} from 'vant';
import {allinpayUser} from '../../store/allinpay'
import {WithdrawApply,PayByBackSMS,DepositApply,SignContract,getBankCard} from '../../api/common'
export default {
    components:{
        [NavBar.name]: NavBar,
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell,
        [Field.name]: Field,
        [Button.name]: Button,
        [ActionSheet.name]:ActionSheet,
        [Dialog.Component.name]:Dialog.Component
    },
    data(){
        return {
            smsshow:false,
            smsCode:'',
            cardName:'请选择您的银行卡',
            title:{
                0:'充值',
                1:'提现',
            },
            type:0,
            payType:0,
            amount:'',
            phone:'',
            bankCardId:'',
            actions: [{ name: '选项一' }, { name: '选项二' }, { name: '选项三',subname:'描述信息' }],
            show:false,
            applyData:'',
        }
    },
    methods:{
        beforeClose(){
            if(!this.smsCode){
                this.$toast.fail("请输入验证码");
                return;
            }
            var user  = allinpayUser.get();
            var body = {
                "userSource": user.userSource,
                "verificationCode": this.smsCode
            };
            if(this.type == 0){
                body.bizOrderNo = this.applyData;
            }else{
                body.bizOrderNo = this.applyData.bizOrderNo;
            }
            this.$toast.loading({duration: 0,forbidClick: true,message: '提交中'});
            PayByBackSMS(body).then(res=>{
                this.$toast.clear();
                if(res.status){
                    this.$toast.success(this.title[this.type]+'成功!');
                    this.$router.push('/bank/balance')
                }else{
                    this.$toast({type:res.status?'success':'fail',message:res.msg||res.message});
                }
            }).catch(()=>{
                this.$toast.clear();
                this.$toast.fail('系统异常');
            });
        },
        onSubmit(){
            if(this.amount<0||this.amount>1000000){
                this.$toast.fail('请输入正确的金额');
                return;
            }
            if(!this.bankCardId){
                this.$toast.fail('请选择银行');
                return;
            }
            var source = this.$util.isMobile();
            var user  = allinpayUser.get();
            //充值
            if(this.type==0){
                var body = {
                        userSource:user.userSource,
                        amount:this.amount*100,
                        //amount:this.amount,
                        bankCardId:this.bankCardId,
                        source:source?1:2,
                        payType:this.$enum.payTypeEnum[this.payType].value
                    };
                this.$toast.loading({duration: 0,forbidClick: true,message: '提交中'});
                DepositApply(body).then(res=>{
                    this.$toast.clear();
                    this.$toast({type:res.status?'success':'fail',message:res.msg||res.message});
                    if(this.payType == 1){
                        this.applyData = res.data;
                        //res.status&&(this.smsshow = true);
                        this.smsshow = true;
                    }
                }).catch(()=>{
                    this.$toast.clear();
                });
            }else{
                //提现
                 body = {
                    userSource:user.userSource,
                    amount:this.amount*100,
                    // amount:this.amount,
                    bankCardId:this.bankCardId,
                    source:source?1:2
                };
                this.$toast.loading({duration: 0,forbidClick: true,message: '提交中'});
                WithdrawApply(body).then(res=>{
                    this.$toast.clear();
                    this.$toast({type:res.status?'success':'fail',message:res.msg||res.message});
                    this.applyData = res.data;
                    res.status&&(this.smsshow = true);
                    if(!res.status&&res.message=='会员未签订**电子协议'){
                        var signbody = {
                            "userId":user.id,
                            "userSource": user.source,
                            "jumpPageType": 1,
                            "backUrl":process.env.VUE_APP_PAY_NOTIFY_URL,
                            "jumpUrl": window.origin+"/bank/withdraw?type=1",
                            "noContractUrl": window.origin+"/bank/withdraw?type=1",
                            "source": source?1:2
                        };
                        this.$toast.loading({duration: 0,forbidClick: true,message: '获取电子合约'});
                        SignContract(signbody).then(res=>{
                            if(res.status){
                                window.location.href=res.data.url;
                            }else{
                                this.$toast.clear();
                                this.$toast.fail("请求电子合约失败！");
                            }
                        }).catch(()=>{
                            this.$toast.clear();
                        });
                    }
                }).catch(()=>{
                    this.$toast.clear();
                })
            }
           
        },
        showCard(){
            console.log('showCard');
            this.show = true;
        },
        onSelect(item) {
            console.log(item);
            if(item.id == 0){
                this.$router.push('/bank/add');
                return
            }
            this.cardName = item.name;
            this.bankCardId = item.id;
            this.show = false;
            this.phone = item.phone;
        },
    },
    created(){
        this.type = this.$route.query.type;
        this.payType = this.$route.query.payType;
        console.log(this.type);
        this.actions = [];
        getBankCard({userSource:allinpayUser.get().userSource}).then(res=>{            
            res.forEach(e => {
                this.actions.push({
                    name:e.bankName,
                    subname:e.cardNo,
                    id:e.id,
                    phone:e.phone
                })
            });
            this.actions.push({
                name:'绑定银行卡',
                id:'0',
            })
        })
    }
}
</script>

<style scoped>
.app_bank{
    /* padding: 10px; */
    width: 100%;
}
.input_div{
    width: 100%;
    margin-top: 16px;
    padding-left:10px;
    /* padding: 10px 16px; */
    font-size: 14px;
}
.submit_btn{
    margin-top: 80px;
}
.app_body{
    width: 100%;
}
</style>